export enum CrossError {
  COUPON_EXPIRED = 20,
  COUPON_INVALID_BRAND = 21,
  COUPON_IS_NOT_VALID = 22,
  COUPON_IS_OUT_OF_STOCK = 23,
  COUPON_HAS_BEEN_USED = 26,
  CUSTOMER_NOT_ENOUGH_POINT = 50,
  CUSTOMER_NOT_FOUND = 51,
  CUSTOMER_GROUP_NOT_FOUND = 52,
  CUSTOMER_IS_IN_ANOTHER_GROUP = 53,
  CUSTOMER_ALREADY_IN_GROUP = 54,
  CUSTOMER_NOT_IN_LOYALTY = 55,
  MAXIMUM_GROUP_REACHED = 100
}

const mapCrossErrorMessage: { [key: number]: string } = {
  [CrossError.COUPON_EXPIRED]: "รีวอร์ดหมดอายุแล้ว",
  [CrossError.COUPON_INVALID_BRAND]: "รีวอร์ดไม่ถูกต้อง",
  [CrossError.COUPON_IS_NOT_VALID]: "รีวอร์ดไม่ถูกต้อง",
  [CrossError.COUPON_IS_OUT_OF_STOCK]: "รีวอร์ดถูกใช้สิทธิเต็มแล้ว",
  [CrossError.COUPON_HAS_BEEN_USED]: "รีวอร์ดเคยถูกใช้แล้ว",
  [CrossError.CUSTOMER_NOT_ENOUGH_POINT]: "แต้มสะสมไม่เพียงพอ",
  [CrossError.CUSTOMER_NOT_FOUND]: "ไม่พบข้อมูล",
  [CrossError.CUSTOMER_GROUP_NOT_FOUND]: "ไม่พบข้อมูลครอบครัว",
  [CrossError.CUSTOMER_IS_IN_ANOTHER_GROUP]: "เป็นสมาชิกครอบครัวอยู่แล้ว",
  [CrossError.CUSTOMER_ALREADY_IN_GROUP]: "เป็นสมาชิกครอบครัวอยู่แล้ว",
  [CrossError.CUSTOMER_NOT_IN_LOYALTY]: "ยังไม่ได้ลงทะเบียนสะสมแต้ม\nกรุณาลงทะเบียนก่อนส่งคำเชิญ",
  [CrossError.MAXIMUM_GROUP_REACHED]: "การเชิญนี้จะทำให้จำนวนสมาชิกเกินขีดจำกัด"
};

export const getErrorMessage = (
  error: number | undefined
): string | undefined => (error ? mapCrossErrorMessage[error] : undefined);
