


















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import HomeIcon from "@/components/icons/HomeIcon.vue";
import RewardIcon from "@/components/icons/RewardIcon.vue";
import HistoryIcon from "@/components/icons/HistoryIcon.vue";
import ProfileIcon from "@/components/icons/ProfileIcon.vue";
import GroupAddIcon from "@/components/icons/GroupAddIcon.vue";
import { Route } from "vue-router";
import { mapGetters } from "vuex";

import loyaltyConfig from '../../../loyalty_config.json'

const buttonRoute = [
  "Customer_Home",
  "Customer_Reward",
  "Customer_History",
  "Customer_Profile",
  "Customer_Group"
];

@Component({
  components: {
    HomeIcon,
    RewardIcon,
    HistoryIcon,
    ProfileIcon,
    GroupAddIcon
  },
  computed: {
    ...mapGetters("customer", ["getPointCollectType"]),
  }
})
export default class BottomNavigation extends Vue {
  index = 0;
  @Prop({ default: false })
  active!: boolean;

  private currentTheme = this.$vuetify.theme.themes.light;

  // private primaryColor = this.currentTheme.primary;
  // private secondaryColor = this.currentTheme.secondary;

  get primaryColor () {
    return loyaltyConfig?.bottom_navigation?.text_color_selected || this.currentTheme.primary
  }

  get secondaryColor () {
    return loyaltyConfig?.bottom_navigation?.text_color || this.currentTheme.secondary
  }

  mounted() {
    console.log(`Current route : ${this.$route.name}`);
    this.updateNavigate();
  }

  redirect(buttonIndex: number) {
    if (this.index == buttonIndex) return;
    console.log(`Redirect to ${buttonRoute[buttonIndex]}`);
    this.$router.push({ name: buttonRoute[buttonIndex] });
  }

  @Watch("$route")
  onChildChanged(to: Route, from: Route) {
    this.updateNavigate(to.name + "");
  }

  updateNavigate(routeName: string = this.$route.name + "") {
    const currentRouteIndex = buttonRoute.indexOf(routeName);
    this.index = currentRouteIndex > -1 ? currentRouteIndex : this.index;
  }
}
