import { authHeader } from "@/helper/auth-header";
import router from "@/router";
import axios, { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import store from "@/store";
import {
  CustomerLoginResult,
  LoginResult,
  CustomerDetail,
  CustomerProfile,
  OtpData,
  VerifyData,
  IsOldUserData,
  LineUserData
} from "@/common/type";
import { genderToString } from "@/common";
import APIConnector from "./APIConnector";
import {
  CustomerBrandProfile,
  CustomerCredentialRequest,
  CustomerShopList,
  ExchangeCouponResult,
  RegisterCustomerData,
  RegisterCustomerDataRequest,
} from "@/common/type/service/customerAPIConnector";
import { CouponData, CouponHistory } from "@/common/type/coupon";

import loyaltyConfig from '../../loyalty_config.json'

class CustomerAPIConnector extends APIConnector {
  constructor() {
    super();
  }

  async customerLogin(
    accessToken: string,
    idToken: string
  ): Promise<CustomerLoginResult | { isRegister: boolean } | undefined> {
    try {
      const authData: CustomerCredentialRequest = {
        strategy: "line",
        access_token: accessToken,
        id_token: idToken,
      };

      if(loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
        authData.brand_id = loyaltyConfig.brand_id
        authData.type = 'CDP'
      }

      const authResult: AxiosResponse = await axios({
        method: "post",
        url: `${this.APIPath}/authentication`,
        data: qs.stringify(authData),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const { data } = authResult;
      const result: CustomerLoginResult = {
        accessToken: data.accessToken,
        role: "customer",
        user: data.user as CustomerProfile,
      };
      return result;
    } catch (e: any) {
      if (e.response.status === 404) {
        console.log("Customer Account is not exist");
        router.replace({ name: "Customer_Check_Phone" });
        return { isRegister: true };
      } else {
        console.log("Customer Login Failed");
        this.handleAPIFailed(e);
        return undefined;
      }
    }
  }

  async registerCustomerAccount(
    accessToken: string,
    idToken: string,
    customerData: RegisterCustomerData
  ): Promise<void> {
    try {
      const registerData: RegisterCustomerDataRequest = { 
        access_token: accessToken,
        id_token: idToken,
        firstname: customerData.firstname,
        lastname: customerData.lastname,
        birthdate: customerData.birthdate,
        gender: genderToString[customerData.gender],
        phone: customerData.phone,
        consent_accept: customerData.consent_accept,
        type: customerData.type
      };
      if(customerData.type === 'CONVERT' || customerData.type === 'NOT_MEMBER') {
        registerData._id = customerData._id
      }
      if(loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
        registerData.brand_id = loyaltyConfig.brand_id
        registerData.type = 'CDP'
      }
      console.log(registerData);
      const authResult = await axios({
        method: "post",
        url: `${this.APIPath}/register/customer`,
        data: qs.stringify(registerData),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      localStorage.removeItem("customer");
      router.push({ name: "Customer_Shoplist" });
    } catch (e: any) {
      console.log("Customer Register Failed");
      this.handleAPIFailed(e);
    }
  }

  async getQr(qrCode: string) {
    try {
      const qr = await axios({
        method: "get",
        url: `${this.APIPath}/qr-code-generate?qr_code=${qrCode}`,
      });
      return qr.data;
    } catch (e: any) {
      console.log("Error get QRCode");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async redeemQr(rawData: any) {
    try {
      const redeem = await axios({
        method: "post",
        url: `${this.APIPath}/qr-code-generate/redeem`,
        data: rawData,
        headers: authHeader(),
      });
      return redeem.data;
    } catch (e: any) {
      console.log("Error get QRCode");
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async handleAPIFailed(e: AxiosError) {
    if (e.response?.status == 401) {
      console.log("Authentication Failed, auto logout");
      await store.dispatch("customer/logout");
      store.dispatch("customer/lineLogin");
    } else if (e.response?.status == 406) {
      console.log("Authentication Failed, auto logout");
      await store.dispatch("customer/logout");
      store.dispatch("customer/lineLogin");
    } else {
      console.log("Customer API Error");
      console.dir(e);
    }
  }

  async getCustomerShopList(
    keyword: string
  ): Promise<CustomerShopList[] | undefined> {
    try {
      const authResult = await axios({
        method: "get",
        url: `${this.APIPath}/brands${keyword ? `?keyword=${keyword}` : ``}`,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data as CustomerShopList[];
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getCustomerBrandProfile(
    brandId: string
  ): Promise<CustomerBrandProfile | undefined> {
    try {
      const authResult = await axios({
        method: "get",
        url: `${this.APIPath}/brand/${brandId}/profile`,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data as CustomerBrandProfile;
    } catch (e: any) {

      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async checkIsOldUser(
    phone: string,
    accessToken: string,
    idToken: string,
  ): Promise<any | undefined> {
    try {
      const token = {
        access_token: accessToken,
        id_token: idToken
      }

      if(loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
        // @ts-ignore
        token.brand_id = loyaltyConfig.brand_id
      }
      const authResult = await axios({
        method: "post",
        url: `${this.APIPath}/customer/check-is-old-user?phone=${phone}`,
        data: qs.stringify(token),
        headers: authHeader(),
      });
      const { data } = authResult;
      return data as IsOldUserData;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async checkExternalId(
    externalId: string,
    brandId: string
  ): Promise<any | undefined> {
    try {
      const authResult = await axios({
        method: "get",
        url: `${this.APIPath}/customer/external-id/${externalId}?brand_id=${brandId}`,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async sendOTP(
    userId: string,
    accessToken: string,
    idToken: string,
  ): Promise<any | undefined> {
    try {
      const token = {
        access_token: accessToken,
        id_token: idToken
      }

      if (loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
        // @ts-ignore
        token.brand_id = loyaltyConfig.brand_id;
      }

      const authResult = await axios({
        method: "patch",
        url: `${this.APIPath}/customer/otp/${userId}`,
        data: qs.stringify(token),
        headers: authHeader(),
      });
      const { data } = authResult;
      return data as OtpData;
    } catch (e: any) {
      if (e.response?.status == 500) {
        return { isError: true };
      }
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async verifyOTP(
    userId: string,
    otp: string,
    refCode: string,
    accessToken: string,
    idToken: string,
  ): Promise<any | undefined> {
    try {
      const otpData: OtpData = {
        otp: otp,
        ref_code: refCode
      };
      const token = {
        access_token: accessToken,
        id_token: idToken
      }

      if (loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
        // @ts-ignore
        token.brand_id = loyaltyConfig.brand_id;
      }

      const authResult = await axios({
        method: "patch",
        url: `${this.APIPath}/customer/verify-otp/${userId}`,
        data: qs.stringify({ ...otpData, ...token}),
        headers: authHeader(),
      });
      const { data } = authResult;
      return data as VerifyData;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async updateConsentAccept(
    userId: string,
    accessToken: string,
    idToken: string,
  ): Promise<any | undefined> {
    try {
      const token = {
        access_token: accessToken,
        id_token: idToken
      }
      const authResult = await axios({
        method: "patch",
        url: `${this.APIPath}/customer/consent_accept/${userId}`,
        data: qs.stringify(token),
        headers: authHeader(),
      });
      const { data } = authResult;
      return data as VerifyData;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getOldUserData(
    userId: string
  ): Promise<any | undefined> {
    try {
      const authResult = await axios({
        method: "get",
        url: `${this.APIPath}/customer/profile/${userId}`,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data as RegisterCustomerData;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandData(
    brandId: string
  ): Promise<CustomerBrandProfile | undefined> {
    try {
      const authResult = await axios({
        method: "get",
        url: `${this.APIPath}/brand/${brandId}`,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandCouponList(brandId: string, userId: string): Promise<CouponData[] | undefined> {
    try {
      let url = `${this.APIPath}/brand/${brandId}/coupons`
      if (loyaltyConfig?.brand_id && loyaltyConfig?.brand_id !== '') {
        url += `/${userId}`
      }
      const authResult = await axios({
        method: "get",
        url: url,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getBrandCoupon(
    couponId: string,
    brandId: string
  ): Promise<CouponData | undefined> {
    try {
      const authResult = await axios({
        method: "get",
        url: `${this.APIPath}/brand/${brandId}/coupon/${couponId}`,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async exchangeCoupon(
    couponId: string,
    brandId: string,
    exchangeUser?: string
  ): Promise<ExchangeCouponResult | undefined> {
    try {
      let url = `${this.APIPath}/brand/${brandId}/exchange-coupon/${couponId}`
      const authResult = await axios({
        method: "post",
        url: `${this.APIPath}/brand/${brandId}/exchange-coupon/${couponId}`,
        data: qs.stringify({ exchange_user: exchangeUser }),
        headers: authHeader(),
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async getCustomerCouponHistory(
    brandId: string
  ): Promise<CouponHistory[] | undefined> {
    try {
      const authResult = await axios({
        method: "get",
        url: `${this.APIPath}/customer/${brandId}/history/coupons`,
        headers: authHeader(),
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async updateCustomerProfile(
    userProfile: CustomerDetail
  ): Promise<CustomerDetail | undefined> {
    try {
      const user = store.getters["customer/userProfile"];
      if (!user) {
        throw new Error("Not Authenticated");
      }
      const updateData = qs.stringify({
        firstname: userProfile.firstname,
        lastname: userProfile.lastname,
        birthdate: userProfile.birthdate,
        phone: userProfile.phone,
        gender: genderToString[userProfile.gender],
        email: userProfile.email,
      });
      const authResult = await axios({
        method: "patch",
        url: `${this.APIPath}/customer/${user._id}`,
        headers: authHeader(),
        data: updateData,
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async updateLineUserData(
    lineUserData: LineUserData
  ): Promise<CustomerDetail | undefined> {
    try {
      const user = store.getters["customer/userProfile"];
      if (!user) {
        throw new Error("Not Authenticated");
      }
      const updateData = qs.stringify({
        line_display_name: lineUserData.displayName,
        img: lineUserData.pictureUrl,
      });
      const authResult = await axios({
        method: "patch",
        url: `${this.APIPath}/update-line-customer-data/${user._id}`,
        headers: authHeader(),
        data: updateData,
      });
      const { data } = authResult;
      return data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async activatingBrand (
    brandId: string
  ): Promise<any|undefined> {
    try {
      const user = store.getters["customer/userProfile"];
      if (!user) {
        throw new Error("Not Authenticated");
      }
      const updateData = qs.stringify({
        customer_id: user._id,
        brand_id: brandId
      });
      const authResult = await axios({
        method: "patch",
        url: `${this.APIPath}/activateBrand`,
        headers: authHeader(),
        data: updateData,
      });
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }
}

export default new CustomerAPIConnector();
