import Vue from "vue";
import Vuex from "vuex";
import { alert } from "./alert";
import branch from "./branch";
import brand from "./brand";
import customer from "./customer";
import agency from "./agency";
import group from "./group";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    customer,
    branch,
    brand,
    alert,
    agency,
    group
  },
  strict: false,
});
