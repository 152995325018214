









































































import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { getErrorMessage, CrossError } from "@/common/crossError";

import loyaltyConfig from '../../../loyalty_config.json'

@Component({
  computed: {
    ...mapGetters("customer", [
      "selectedBrandId",
      "customerGroupId"
    ]),
    ...mapGetters("group", [
      "getMaxMember"
    ]),
  },
})


export default class CustomerCheckPhone extends Vue {
  // phoneImg: string = require(`@/assets/img/phone.png`);
  valid: boolean = true
  telNo: string = ""
  errorMessages: string = ""
  isError: boolean = false
  inviteErrorMessage: string = ""
  selectedBrandId!: string;
  customerGroupId!: string;
  getMaxMember!: number;
  //@ts-ignore
  rules:Array<Function> = [
    (value: string) => !!value || '* กรุณากรอกเบอร์โทรศัพท์',
    (value: string) => !!(new RegExp('^([0]){1}([689]){1}([0-9]){8}$')).test(value) || '* เบอร์โทรศัพท์ของคุณไม่ถูกต้อง'
  ]

  async mounted() {
    if (!this.selectedBrandId || !this.customerGroupId) {
      this.$router.replace({ name: "Customer_Home" });
    }
  }

  async confirmTelNo() {
    //@ts-ignore
    if(this.$refs.form.validate()){
      const response = await this.$store.dispatch('group/inviteCustomerToGroup', {
        brandId: this.selectedBrandId,
        groupId: this.customerGroupId,
        customerPhone: this.telNo
      }).catch((error: any) => {
        this.isError = true
        this.inviteErrorMessage = "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง"
      })
      if (response.result) {
        this.$store.dispatch('alert/success', 'ส่งคำเชิญสำเร็จ')
        this.$router.go(-1)
      } else {
        this.isError = true
        if (response.errorCode === CrossError.MAXIMUM_GROUP_REACHED) {
          let errMsg = getErrorMessage(response.errorCode) ?? ''
          if (errMsg) {
            errMsg = `${errMsg}\n(สูงสุด ${this.getMaxMember} คน)`
          } else {
            errMsg = response.msg
          }
          this.inviteErrorMessage = errMsg
        } else {
          this.inviteErrorMessage = getErrorMessage(response.errorCode) ?? response.msg
        }
      }
    }
  }

  cancel() {
    this.$router.go(-1)
  }

  handleChange () {
    if (this.errorMessages != "") {
      this.errorMessages = ""
    }
  }

  confirmModal() {
    this.isError = false
    this.inviteErrorMessage = ""
  }

}
