import { authHeader } from "@/helper/auth-header";
import router from "@/router";
import axios, { AxiosError, AxiosResponse } from "axios";
import qs from "qs";
import store from "@/store";
import APIConnector from "./APIConnector";
import { GroupProfile, InviteResult, PendingResult, AcceptResult } from "@/common/type/service/groupAPIConnector";

import loyaltyConfig from '../../loyalty_config.json'

class GroupAPIConnector extends APIConnector {
  constructor() {
    super();
  }

  async fetchGroupProfile(brandId: string, groupId: string): Promise<GroupProfile | undefined> {
    try {
      const response = await axios.get(`${this.APIPath}/customer-group/${brandId}/profile/${groupId}`, {
        headers: authHeader()
      });
      return response.data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return undefined;
    }
  }

  async inviteCustomerToGroup(brandId: string, groupId: string, customerPhone: string): Promise<InviteResult> {
    try {
      const response = await axios.post(`${this.APIPath}/customer-group/${brandId}/invite/${groupId}?phone=${customerPhone}`, {}, {
        headers: authHeader()
      });
      return response.data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return { result: false };
    }
  }

  async isPendingGroup(brandId: string): Promise<PendingResult> {
    try {
      const response = await axios.get(`${this.APIPath}/customer-group/${brandId}/is-pending`, {
        headers: authHeader()
      });
      return response.data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return { result: false };
    }
  }

  async acceptInviteGroup(brandId: string, groupId: string): Promise<AcceptResult> {
    try {
      const response = await axios.post(`${this.APIPath}/customer-group/${brandId}/accept/${groupId}`, {}, {
        headers: authHeader()
      });
      return response.data;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return {
        result: false
      };
    }
  }

  async cancelInviteGroup(brandId: string, groupId: string): Promise<Boolean> {
    try {
      const response = await axios.post(`${this.APIPath}/customer-group/${brandId}/cancel/${groupId}`, {}, {
        headers: authHeader()
      });
      return response.data.result;
    } catch (e: any) {
      this.handleAPIFailed(e);
      return false;
    }
  }

  async handleAPIFailed(e: AxiosError) {
    if (e.response?.status == 401) {
      console.log("Authentication Failed, auto logout");
      await store.dispatch("customer/logout");
      store.dispatch("customer/lineLogin");
    } else if (e.response?.status == 406) {
      console.log("Authentication Failed, auto logout");
      await store.dispatch("customer/logout");
      store.dispatch("customer/lineLogin");
    } else {
      console.log("Customer API Error");
      console.dir(e);
    }
  }
}

export default new GroupAPIConnector();