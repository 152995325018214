import {
  BrandData,
  BrandDataState,
  CustomerLoginResult,
  CustomerState,
  LineLoginCallback,
  LoginCredential,
  CustomerProfile,
} from "@/common/type";
import { $liff } from "@/main";
import router from "@/router";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import { Liff } from "@line/liff/dist/lib";
import { ActionContext } from "vuex";
import store from ".";

const state: CustomerState = {
  loggingIn: false,
  accessToken: "",
  userProfile: {
    _id: "",
    role: "",
    uid_line: "",
    firstname: "",
    lastname: "",
    birthdate: "",
    gender: "",
    email: "",
    phone: "",
    img: "",
    loyalty_receive_code: "",
    current_point: undefined,
    group_id: "",
    group_point: undefined,
    consent_accept: false,
  },
  brandData: {
    brandName: "",
    brandId: "",
    brandLogo: "",
    brandPoster: "",
    brandPointCollectType: "CUSTOMER",
    branchPoint: 0
  },
  isRegister: false,
  isOTPVerified: false,
  redirectAfterVerified: "Customer_Register",
  isOldUser: false,
  isGetQrReward: false,
  redeemText: "",
  // Accessibility
  isGoToRewards: false,
  shopIdToRedirect: undefined,
};
const actions = {
  lineLogin: async (
    { state, dispatch, commit }: ActionContext<CustomerState, {}>,
    callback?: LineLoginCallback
  ) => {
    //@ts-ignore
    const liff: Liff = $liff;
    const userLocalStorage = JSON.parse(localStorage.getItem("customer") + "");
    if (!userLocalStorage) {
      console.log("Invalid or No authentication data");
      liff.ready.then(async () => {
        const isLoggedIn = liff.isLoggedIn();
        if (!isLoggedIn) {
          // external browser
          await liff.login({
            redirectUri: `${process.env.VUE_APP_DOMAIN}/customer/shop-list`,
          });
          // will be redirect by Line Liff anyway
          return;
        }
        // console.log("line auth");
        const idToken = liff.getIDToken();
        const accessToken = liff.getAccessToken();
        // console.log("Get idToken", idToken);
        // console.log("Get accessToken", accessToken);
        const idTokenEXP = liff.getDecodedIDToken()?.exp || 0;
        if (Date.now() >= idTokenEXP * 1000) {
          await liff.logout();
          await liff.login({
            redirectUri: `${process.env.VUE_APP_DOMAIN}/customer/shop-list`,
          });
          // will be redirect by Line Liff anyway
          return;
        }
        await dispatch("login", {
          accessToken,
          idToken,
          router: router,
        });
        console.log("line login complete");
        callback?.(state.isRegister) ||
          router.push({ name: "Customer_Shoplist" });
      });
    } else {
      console.log("Found authentication data");
      switch (userLocalStorage.role) {
        case "admin":
          console.log(
            "Found brand account, auto logout and try customer login again"
          );
          store.dispatch("brand/logout", false);
          // perform line login again with the same callback
          dispatch("lineLogin", callback);
          break;
        case "staff":
          console.log(
            "Found branch account, auto logout and try customer login again"
          );
          store.dispatch("branch/logout", false);
          // perform line login again with the same callback
          dispatch("lineLogin", callback);
          break;
        default:
          console.log("Found customer account, Already login");
          commit("updateLoginData", userLocalStorage);
          callback?.() || router.push({ name: "Customer_Shoplist" });
      }
    }
  },
  login: async (
    { dispatch, commit }: ActionContext<CustomerState, {}>,
    { accessToken, idToken }: LoginCredential
  ) => {
    const loginResult = await CustomerAPIConnector.customerLogin(
      accessToken,
      idToken
    );
    if (loginResult) {
      if ("isRegister" in loginResult && loginResult.isRegister) {
        console.log("Register new account");
        commit("toggleIsRegister");
      } else {
        console.log("Login pass");
        commit("updateLoginData", loginResult);
      }
    } else {
      console.log("Auth Failed");
    }
  },
  logout: async ({ dispatch, commit }: ActionContext<CustomerState, {}>) => {
    commit("logout");
    console.log("Logout");
  },
  fetchBrandData: async (
    { dispatch, commit }: ActionContext<CustomerState, {}>,
    { brandId }: { brandId: string }
  ) => {
    console.log(`Enter brand id ${brandId}`);
    console.log(`Fetching customer brand profile`);
    const customerProfile = await CustomerAPIConnector.getCustomerBrandProfile(
      brandId
    );
    if (!customerProfile) {
      console.log("Shop not found");
      store.dispatch("alert/error", "ร้านค้าไม่ถูกต้อง");
      return;
    } else {
      const brandData: BrandData | undefined = customerProfile.brand
        ? { ...customerProfile.brand }
        : undefined;
      delete customerProfile.brand;
      if (brandData) {
        let showPointRate = true
        if (customerProfile.loyalty_show_point_rate !== undefined) {
          showPointRate = customerProfile.loyalty_show_point_rate
        }
        let brandPointCollectType = "CUSTOMER"
        if (brandData.loyalty_point_collection_type) {
          brandPointCollectType = brandData.loyalty_point_collection_type
        }
        commit("setBrandData", {
          brandName: brandData.name,
          brandId: brandData.brand_id,
          brandLogo: brandData.logo,
          brandPoster: brandData.poster,
          branchPoint: customerProfile.point,
          brandPointCollectType,
          showPointRate,
        });
      }
      commit("setUserProfile", customerProfile);
      dispatch("activateBrand", brandId);
      return customerProfile
    }
  },
  fetchCustomerProfile: async (
    { dispatch, commit, state }: ActionContext<CustomerState, {}>,
    { brandId, callback }: { brandId: string; callback?: () => void }
    ) => {
    const currentBrandId = state.brandData.brandId;
    if (!currentBrandId) {
      console.log("Brand target is not selected");
      if (!brandId) {
        router.push({ name: "Customer_Shoplist" });
      }
      const customerProfile = await dispatch("fetchBrandData", { brandId });
      return customerProfile;
    } else {
      if (brandId && currentBrandId != brandId) {
        // brand change, select new brand
        commit("resetSelectBrandData");
        dispatch("fetchBrandData", { brandId });
      }
    }
    console.log(`Fetching customer brand profile`);
    const customerProfile = await CustomerAPIConnector.getCustomerBrandProfile(
      brandId
    );
    if (customerProfile) {
      delete customerProfile.brand;
      commit("setUserProfile", customerProfile);
    } else {
      console.log("failed to fetch customer profile");
    }
    callback?.();
    return customerProfile
  },
  activateBrand: async ({ commit, state }: any, brandId: string) => {
    try {
      const activate = await CustomerAPIConnector.activatingBrand(brandId)
    } catch {
      console.log("failed to activated new brand");
    }
  },
  redeemQrCode: async ({ commit, state }: any, qrCode: string) => {
    try {
      const data = {
        qr_code: qrCode
      }
      const redeem = await CustomerAPIConnector.redeemQr(data);
      if (redeem.errors) {
        commit("SET_IS_GET_QR_REWARD", false);
        commit("SET_REDEEM_TEXT", "QR นี้ถูกใช้ไปแล้ว");
      } else {
        const point = state.userProfile.current_point ?? 0;
        const newPoint = point + redeem.point;
        state.userProfile.current_point = newPoint;
        commit("setUserProfile", state.userProfile);
        commit("SET_IS_GET_QR_REWARD", true);
        commit("SET_REDEEM_TEXT", `คุณได้รับ ${redeem.point} แต้มจากร้าน`);
      }
    } catch {
      commit("SET_IS_GET_QR_REWARD", false);
      commit("SET_REDEEM_TEXT", "ไม่สามารถรับแต้มจาก QR นี้ได้");
    }
  }
};
const mutations = {
  updateLoginData: (state: CustomerState, data: CustomerLoginResult) => {
    state.loggingIn = true;
    state.accessToken = data.accessToken;
    localStorage.setItem("customer", JSON.stringify(data));
    localStorage.setItem("mode", "CUSTOMER");
    state.userProfile = data.user;
  },
  logout: (state: CustomerState) => {
    state.loggingIn = false;
    state.accessToken = "";
    state.userProfile = {
      _id: "",
      role: "",
      uid_line: "",
      firstname: "",
      lastname: "",
      birthdate: "",
      gender: "",
      email: "",
      phone: "",
      img: "",
      loyalty_receive_code: "",
      current_point: undefined,
      consent_accept: false,
    };
    state.isOTPVerified = false
    localStorage.removeItem("customer");
    localStorage.removeItem("mode");
  },
  resetSelectBrandData: (state: CustomerState) => {
    state.brandData = {
      brandName: "",
      brandId: "",
      brandLogo: "",
      brandPoster: "",
      brandPointCollectType: "CUSTOMER",
      branchPoint: 0,
    };
    if (state.userProfile?.current_point) {
      state.userProfile.current_point = 0;
    }
  },
  setBrandData: (state: CustomerState, brandData: BrandDataState) => {
    state.brandData = brandData;
  },
  setUserProfile: (state: CustomerState, userProfile: CustomerProfile) => {
    state.userProfile = userProfile;
    //@ts-ignore
    const customer = JSON.parse(localStorage.getItem("customer"));
    localStorage.setItem("customer", JSON.stringify({ ...customer, user: userProfile }));
    localStorage.setItem("mode", "CUSTOMER");
    state.isOTPVerified = !!userProfile.otp_verify;
  },
  SET_USER_PROFILE_PHONE: (state: CustomerState, phone: string) => {
    state.userProfile.phone = phone;
  },
  SET_USER_ID: (state: CustomerState, userId: string) => {
    state.userProfile._id = userId;
  },
  SET_IS_OLD_USER: (state: CustomerState, isOldUser: boolean) => {
    state.isOldUser = isOldUser;
  },
  SET_REDIRECT_AFTER_VERIFIED: (state: CustomerState, redirect: string) => {
    state.redirectAfterVerified = redirect;
  },
  SET_IS_GET_QR_REWARD: (state: CustomerState, isGetReward: boolean) => {
    state.isGetQrReward = isGetReward;
  },
  SET_REDEEM_TEXT: (state: CustomerState, redeemText: string) => {
    state.redeemText = redeemText;
  },
  toggleIsRegister: (state: CustomerState) => {
    state.isRegister = true;
  },
};
const getters = {
  isLoggedIn: (state: CustomerState) => state.loggingIn,
  isRegister: (state: CustomerState) => state.isRegister,
  userProfile: (state: CustomerState) => state.userProfile,
  customerFullName: (state: CustomerState) =>
    `${state.userProfile.firstname} ${state.userProfile.lastname}`,
  customerPoint: (state: CustomerState) => state.userProfile.current_point ?? 0,
  customerGroupId: (state: CustomerState) => state.userProfile.group_id,
  customerGroupPoint: (state: CustomerState) => state.userProfile.group_point ?? 0,
  customerImg: (state: CustomerState) => state.userProfile.img,
  customerReceiveCode: (state: CustomerState) =>
    state.userProfile.loyalty_receive_code,
  selectedBrandId: (state: CustomerState) => state.brandData.brandId,
  selectedBrandName: (state: CustomerState) => state.brandData.brandName,
  selectedBrandLogo: (state: CustomerState) => state.brandData.brandLogo,
  selectedBrandPoster: (state: CustomerState) => state.brandData.brandPoster,
  isOTPVerified: (state: CustomerState) => state.isOTPVerified,
  isBrandReady: (state: CustomerState) => state.brandData.brandId != "",
  getBrandPoint: (state: CustomerState) => state.brandData.branchPoint,
  getPointCollectType: (state: CustomerState) => state.brandData.brandPointCollectType,
  getShowPointRate: (state: CustomerState) => state.brandData.showPointRate,
  isGetQrReward: (state: CustomerState) => state.isGetQrReward,
  redeemText: (state: CustomerState) => state.redeemText
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
