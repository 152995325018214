



































































































































































































































import { Gender, GenderItems, GenderLabels } from "@/common";
import { CustomerDetail } from "@/common/type";
import { Liff } from "@line/liff/dist/lib";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import validator from "validator";
import CustomerAPIConnector from "@/service/CustomerAPIConnector";
import ConsentModal from "@/components/register/ConsentModal.vue";
import { mapGetters } from "vuex";
import { CustomerProfile } from "@/common/type";

import loyaltyConfig from '../../../loyalty_config.json'

@Component({
  components: {
    ConsentModal
  },
  computed: {
    ...mapGetters("customer", ["userProfile"]),
    aipenLogoURL() {
      if (!loyaltyConfig?.loyalty_logo || loyaltyConfig?.loyalty_logo === "") {
        return require(`@/assets/img/sable_logo.svg`);
      }
      return loyaltyConfig.loyalty_logo
    }
  },
})
export default class CustomerRegister extends Vue {
  userImage: string = "";
  genderItems = GenderItems;
  genderLabel = GenderLabels;

  confirmConsent: boolean = true;
  isShowConsentModal: boolean = false;
  isClick: boolean = false;
  aipenLogoURL!: string;

  // userDetail: UserDetail = {
  //   firstname: "",
  //   lastname: "",
  //   birthdate: moment(new Date()).format("YYYY-MM-DD"),
  //   phone: "",
  //   gender: Gender.male,
  //   email: "",
  // };

  registerData: any = {
    firstname: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุชื่อ";
        },
      ],
    },
    lastname: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุนามสกุล";
        },
      ],
    },
    birthdate: {
      data: null,
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุวันเกิด";
        },
      ],
    },
    gender: {
      data: Gender.male,
      warning: "",
      rules: [],
    },
    phone: {
      data: "",
      warning: "",
      rules: [
        function (this: any, v: string) {
          return !!v || "กรุณาระบุเบอร์โทร";
        },
        function (this: any, v: string) {
          return (
            validator.isMobilePhone(v, "th-TH") || "เบอร์โทรศัพท์ไม่ถูกต้อง"
          );
        },
      ],
    },
  };

  showButton = true;
  datePickerModal = false;
  userProfile!:CustomerProfile;

  //@ts-ignore
  liff: Liff = this.$liff;
  lineAccessToken = "";
  lineIdToken = "";

  activePicker = "YEAR";

  mounted() {
    this.liff.ready.then(() => {
      if (this.liff.isLoggedIn()) {
        this.liff.getProfile().then((profile: any) => {
          this.userImage = profile.pictureUrl;
        });
        this.lineAccessToken = this.liff.getAccessToken() + "";
        this.lineIdToken = this.liff.getIDToken() + "";
      } else {
        console.log(
          "Customer Register: Line is not login, redirect to shop-list"
        );
        // Shoplist is the first page for customer workflow and
        // have Line Liff Login handler with auto login to Loyalty API
        // or redirect to register when line account not exist in database
        this.$router.push({ name: "Customer_Shoplist" });
      }
    });
    if(this.$store.state.customer.isOldUser) {
      this.registerData.phone.data = this.userProfile.phone
      if(this.userProfile.type === "OLD" || this.userProfile?.brand_id !== undefined) {
        this.registerData.firstname.data = this.userProfile.firstname
        this.registerData.lastname.data = this.userProfile.lastname
        this.registerData.birthdate.data = moment(this.userProfile.birthdate).format("YYYY-MM-DD")
        this.registerData.gender.data = this.userProfile.gender === 'male' ? Gender.male : Gender.female
      }
    } else {
      if(this.userProfile.phone) {
        this.registerData.phone.data = this.userProfile.phone
      }
    }
  }

  toggleConsentModal (value: boolean) {
    this.isShowConsentModal = value
  }

  resetActivePicker() {
    setTimeout(() => (this.activePicker = "YEAR"));
  }

  async verifyLineToken() {
    const idTokenEXP = this.liff.getDecodedIDToken()?.exp || 0;
    if (Date.now() >= idTokenEXP * 1000) {
      // id token is expired
      await this.liff.logout();
      // redirect to login line liff again;
      this.$router.push({ name: "LineLogin" });
      return false;
    }
    return true;
  }

  validateAll(data: any[] = this.registerData) {
    let valid = true;
    for (const key in data) {
      if (data[key].hasOwnProperty("data")) {
        const result = this.doValidate(data[key]);
        if (!result) valid = false;
      } else {
        const result = this.validateAll(data[key]);
        if (!result) valid = false;
      }
    }
    return valid;
  }

  doValidate(field: any) {
    let valid = true;
    field.rules.forEach((validateCheck: any) => {
      const result = validateCheck.bind(this)(field.data);
      if (result != true) {
        field.warning = result;
        valid = false;
      }
    });
    if (valid) field.warning = "";
    return valid;
  }

  async register() {
    const validateResult = this.validateAll();
    const verifyToken = this.verifyLineToken();
    const userType = this.userProfile.type === 'NOT_MEMBER' ? 'NOT_MEMBER'
      : this.$store.state.customer.isOldUser ? 'CONVERT' 
      : 'MEMBER'
    if (validateResult && verifyToken) {
      this.isClick = true;
      const registerData = {
        firstname: this.registerData.firstname.data,
        lastname: this.registerData.lastname.data,
        birthdate: this.registerData.birthdate.data,
        gender: this.registerData.gender.data,
        phone: this.registerData.phone.data,
        consent_accept: this.confirmConsent,
        type: userType
      };
      if(this.$store.state.customer.isOldUser) {
        //@ts-ignore
        registerData._id = this.userProfile._id
      }
      await CustomerAPIConnector.registerCustomerAccount(
        this.lineAccessToken,
        this.lineIdToken,
        registerData
      );
      this.$store.commit('customer/SET_IS_OLD_USER', false)
    }
  }
}
