import { GroupState } from "@/common/type/store/group";
import { ActionContext } from "vuex";
import GroupAPIConnector from "@/service/GroupAPIConnector";
import store from ".";

const state: GroupState = {
  groupId: "",
  groupPoint: 0,
  maxMember: -1,
  customerInGroup: []
};

const actions = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fetchGroupProfile({ commit }: ActionContext<GroupState, {}>,
    { brandId, groupId }: { brandId: string, groupId: string }) 
  {
    const fetchData = await GroupAPIConnector.fetchGroupProfile(brandId, groupId);
    console.log('🚀 ~ fetchData:', fetchData)
    if (fetchData) {
      commit("updateGroupData", fetchData);
    }
  },

  async inviteCustomerToGroup({ commit }: ActionContext<GroupState, {}>,
    { brandId, groupId, customerPhone }: { brandId: string, groupId: string, customerPhone: string }) 
  {
    const inviteResult = await GroupAPIConnector.inviteCustomerToGroup(brandId, groupId, customerPhone);
    return inviteResult;
  },

  async isPendingGroup({ commit }: ActionContext<GroupState, {}>,
    { brandId }: { brandId: string }) 
  {
    const pendingResult = await GroupAPIConnector.isPendingGroup(brandId);
    return pendingResult;
  },

  async acceptInviteGroup({ commit }: ActionContext<GroupState, {}>,
    { brandId, groupId }: { brandId: string, groupId: string }) 
  {
    const acceptResult = await GroupAPIConnector.acceptInviteGroup(brandId, groupId);
    return acceptResult;
  },

  async cancelInviteGroup({ commit }: ActionContext<GroupState, {}>,
    { brandId, groupId }: { brandId: string, groupId: string }) 
  {
    const cancelResult = await GroupAPIConnector.cancelInviteGroup(brandId, groupId);
    return cancelResult;
  }
};

const mutations = {
  updateGroupData(state: GroupState, data: GroupState) {
    state.groupId = data.groupId;
    state.groupPoint = data.groupPoint;
    state.maxMember = data.maxMember
    state.customerInGroup = data.customerInGroup;
  }
};

const getters = {
  getGroupPoint(state: GroupState) {
    return state.groupPoint;
  },
  getCustomerInGroup(state: GroupState) {
    return state.customerInGroup
  },
  getMaxMember(state: GroupState) {
    return state.maxMember
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};