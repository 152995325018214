



















































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { CustomerGroupState } from '@/common/type/store/group';
import GroupAddIcon from "@/components/icons/GroupAddIcon.vue";
import loyaltyConfig from '../../../loyalty_config.json'
import { getErrorMessage, CrossError } from "@/common/crossError";

@Component({
  components: {
    GroupAddIcon
  },
  computed: {
    ...mapGetters("customer", [
      "selectedBrandId",
      "customerGroupId"
    ]),
    ...mapGetters("group", [
      "getGroupPoint",
      "getCustomerInGroup",
      "getMaxMember"
    ]),
  },
})


export default class CustomerGroup extends Vue {
  isLoading: boolean = false;
  groupCustomer: CustomerGroupState[] = [];
  pendingCustomer: CustomerGroupState[] = [];
  getCustomerInGroup!: CustomerGroupState[];
  meProfile: CustomerGroupState = {
    firstname: "",
    lastname: "",
    phone: "",
    profileImg: "",
    status: "",
    isMe: true,
    isLead: false
  };
  selectedBrandId!: string;
  customerGroupId!: string;
  isHavePending: boolean = false;
  inviteGroup?: string;
  invitePhone?: string;
  isError: boolean = false;
  inviteErrorMessage: string = "";
  getMaxMember!: number;
  private currentTheme = this.$vuetify.theme.themes.light;

  async mounted() {
    this.isLoading = true;
    await this.fetchGroupProfile();
    await this.checkPending();
    setTimeout(() => {
      this.isLoading = false;
    }, 1);
  }

  @Watch("getCustomerInGroup")
  groupCustomerWatcher() {
    if (this.getCustomerInGroup && this.getCustomerInGroup.length > 0) {
      const customerInGroup: CustomerGroupState[] = []
      const pendingCustomer: CustomerGroupState[] = []
      for (let i = 0; i < this.getCustomerInGroup.length; i++) {
        const customerData = this.getCustomerInGroup[i];
        if (customerData.status === "GROUP") {
          customerInGroup.push(customerData);
          if (customerData.isMe) {
            this.meProfile = customerData;
          }
        } else {
          pendingCustomer.push(customerData);
        }
      }
      this.groupCustomer = customerInGroup;
      this.pendingCustomer = pendingCustomer;
    }
  }

  async fetchGroupProfile() {
    if (!this.selectedBrandId || !this.customerGroupId) {
      this.$router.push({ name: "Customer_Home" });
    }
    await this.$store.dispatch("group/fetchGroupProfile", { brandId: this.selectedBrandId, groupId: this.customerGroupId });
  }

  formatPhone (phone: string | undefined) {
    if (!phone) return '';
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  async checkPending () {
    const pendingResult = await this.$store.dispatch("group/isPendingGroup", { brandId: this.selectedBrandId });
    if (pendingResult.result) {
      this.isHavePending = true;
      this.inviteGroup = pendingResult.inviteGroupId;
      this.invitePhone = pendingResult.inviteLeadPhone;
    }
  }

  async acceptInvite () {
    const response = await this.$store.dispatch("group/acceptInviteGroup", { brandId: this.selectedBrandId, groupId: this.inviteGroup });
    if (response.result) {
      this.isHavePending = false;
      this.$router.push({ name: "Customer_Home" });
    } else {
      if (response.errorCode) {
        this.isError = true;
        if (response.errorCode === CrossError.MAXIMUM_GROUP_REACHED) {
          let errMsg = getErrorMessage(response.errorCode) ?? '';
          if (errMsg && errMsg.length > 0) {
            errMsg = `${errMsg}\n(สูงสุด ${this.getMaxMember} คน)`;
          } else {
            errMsg = response.msg ?? '';
          }
          this.inviteErrorMessage = errMsg;
        } else {
          this.inviteErrorMessage = getErrorMessage(response.errorCode) ?? response.msg;
        }
      } else {
        this.$store.dispatch("alert/error", "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง");
      }
    }
  }

  async cancelInvite () {
    const response = await this.$store.dispatch("group/cancelInviteGroup", { brandId: this.selectedBrandId, groupId: this.inviteGroup });
    if (response) {
      this.isHavePending = false;
    } else {
      this.$store.dispatch("alert/error", "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง");
    }
  }

  invite () {
    this.$router.push({ name: "Customer_Group_Invite" });
  }

  get primaryColor () {
    return loyaltyConfig?.bottom_navigation?.text_color_selected || this.currentTheme.primary
  }

  confirmModal() {
    this.isError = false
    this.inviteErrorMessage = ""
  }
}
