













import { Component, Vue } from "vue-property-decorator";
import MenuHistoryIcon from "@/components/icons/menu/MenuHistoryIcon.vue";
import MenuSummaryIcon from "@/components/icons/menu/MenuSummaryIcon.vue";
import MenuCouponManageIcon from "@/components/icons/menu/MenuCouponManageIcon.vue";
import MenuCustomerListIcon from "@/components/icons/menu/MenuCustomerListIcon.vue";
import MenuBranchListIcon from "@/components/icons/menu/MenuBranchListIcon.vue";
import MenuEditProfileIcon from "@/components/icons/menu/MenuEditProfileIcon.vue";
import MenuCouponIcon from "@/components/icons/menu/MenuCouponIcon.vue";
import MenuGivePointIcon from "@/components/icons/menu/MenuGivePoint.vue";
import MenuSableAppIcon from "@/components/icons/menu/MenuSableAppIcon.vue";
import MenuQRIcon from "@/components/icons/menu/MenuQRIcon.vue";
import MenuYellowQRIcon from "@/components/icons/menu/MenuYellowQRIcon.vue";
import LocationIcon from "@/components/icons/LocationIcon.vue";
import ContactModal from "@/components/register/ContactModal.vue";

import MenuBox from "@/components/MenuBox.vue";
import { MenuBoxData } from "@/common/type";

import BrandAPIConnector from "@/service/BrandAPIConnector";

import { mapGetters } from "vuex";

@Component({
  components: {
    MenuHistoryIcon,
    MenuSummaryIcon,
    MenuBox,
    MenuCouponManageIcon,
    MenuCustomerListIcon,
    MenuBranchListIcon,
    MenuEditProfileIcon,
    MenuCouponIcon,
    MenuGivePointIcon,
    MenuQRIcon,
    MenuYellowQRIcon,
    LocationIcon,
    ContactModal
  },
  computed: {
    ...mapGetters("brand", ["brandName", "isSingleBranch", "brandProfile"]),
    ...mapGetters("branch", ["branchName"])
  },
})
export default class ShopOwnerMenu extends Vue {
  shopLogoUrl: string = require(`@/assets/img/sable_logo.svg`);
  isSingleBranch!: boolean
  isShowSableModal: boolean = false
  singleBranchMenu: MenuBoxData[] = [
    // {
    //   name: "giving_point",
    //   label: "ให้แต้ม",
    //   icon: MenuGivePointIcon,
    //   isHighlight: true,
    //   highlightColor: 'Yellow',
    //   routeName: "Branch_GivePoint",
    // },
    {
      name: "redeem_coupon",
      label: "QR code ของฉัน",
      icon: MenuQRIcon,
      highlightColor: 'Green',
      isHighlight: true,
      routeName: "Branch_QR",
    },
    {
      name: "redeem_coupon",
      label: "แลกรีวอร์ด",
      icon: MenuCouponIcon,
      highlightColor: 'Red',
      isHighlight: true,
      routeName: "Branch_RedeemCoupon",
    },
    {
      name: "active_qr",
      label: "เปิดใช้งาน QR",
      icon: MenuYellowQRIcon,
      highlightColor: 'Yellow',
      isHighlight: true,
      routeName: "Branch_ActiveQR",
    },
  ];
  ownerMenu: MenuBoxData[] = [
    {
      name: "customer_list",
      label: "รายชื่อลูกค้า",
      icon: MenuCustomerListIcon,
      routeName: "ShopOwner_CustomerList",
    },
    {
      name: "history",
      label: "ประวัติการใช้งาน",
      icon: MenuHistoryIcon,
      routeName: "ShopOwner_History",
    },
    {
      name: "summary",
      label: "สรุปข้อมูล",
      icon: MenuSummaryIcon,
      isHighlight: false,
      routeName: "ShopOwner_Summary",
    },
    {
      name: "coupon_management",
      label: "จัดการรีวอร์ด",
      icon: MenuCouponManageIcon,
      routeName: "ShopOwner_CouponManage",
    },
    {
      name: "history",
      label: "ตั้งค่าธุรกิจ",
      icon: MenuBranchListIcon,
      routeName: "Shop_Editor_Menu",
    },
    // {
    //   name: "history",
    //   label: "จัดการร้านค้า",
    //   icon: MenuEditProfileIcon,
    //   routeName: "ShopOwner_Profile",
    // },
    {
      name: "history",
      label: "แก้ไขโปรไฟล์",
      icon: MenuEditProfileIcon,
      routeName: "ShopOwner_Staff",
    },
    // {
    //   name: "sableApp",
    //   label: "ขายแบบอัตโนมัติ",
    //   icon: MenuSableAppIcon,
    //   routeName: "Go_To_Sable",
    //   handleClick: () => { this.checkHaveSable() }
    // },
  ]

  get menuItems () {
    if(this.isSingleBranch){
      return [ ...this.singleBranchMenu, ...this.ownerMenu]
    }
    return [{
      name: "redeem_coupon",
      label: "QR code ของฉัน",
      icon: MenuQRIcon,
      highlightColor: 'Green',
      isHighlight: true,
      routeName: "Branch_QR",
    }, ...this.ownerMenu ]
  }

  async checkHaveSable() {
    const { isSableUser } = await BrandAPIConnector.checkIsSableUser()
    if(isSableUser) {
      this.$router.push({ name: 'Go_To_Sable' })
    } else {
      this.toggleSableModal(true)
    }
  }

  toggleSableModal (value: boolean) {
    this.isShowSableModal = value
  }
}
