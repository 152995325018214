















































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import PrimaryButton from "@/components/core/PrimaryButton.vue";
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";
import ScanCode from "@/components/branch/ScanCode.vue";
import CustomerAPI from "@/service/CustomerAPIConnector";
//@ts-ignore
import VueBarcode from "vue-barcode";

import loyaltyConfig from '../../../loyalty_config.json'

@Component({
  components: { QrcodeVue, barcode: VueBarcode, CloseIcon, PrimaryButton, ScanCode },
  computed: {
    ...mapGetters("customer", ['isOTPVerified']),
  },
})
export default class ShowCode extends Vue {
  @PropSync("show", { type: Boolean, default: false })
  showSync!: boolean;
  @Prop({ required: true })
  code!: string;
  @Prop({ default: "" })
  condition!: string;
  @Prop({ default: "-" })
  rewardName!: string;
  @Prop({ required: true })
  logoURL!: string;
  @Prop({ default: false })
  showCondition!: boolean;
  @Prop({ default: "" })
  mode!: string;

  sableDefault: string = require(`@/assets/img/sable_default.svg`);
  // copyIcon: string = require(`@/assets/img/copy.svg`);
  isShowTooltip: boolean = false
  isShowTooltipLinkCopy: boolean = false
  toggleScanCode: boolean = false

  @Watch("isShowTooltip")
  watchIsShowTooltip() {
    if(this.isAddPointMode) {
      this.isShowTooltip = false
    } else {
      if (this.isShowTooltip) {
        setTimeout(() => {
          this.isShowTooltip = false
        }, 800)
      }
    }
  }
  @Watch("isShowTooltipLinkCopy")
  watchIsShowTooltipLinkCopy() {
    if (this.isShowTooltipLinkCopy) {
      setTimeout(() => {
        this.isShowTooltipLinkCopy = false
      }, 800)
    }
  }

  get copyIcon () {
    const image = `
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7895 0H1.68421C0.757895 0 0 0.777273 0 1.72727V13.8182H1.68421V1.72727H11.7895V0ZM14.3158 3.45455H5.05263C4.12632 3.45455 3.36842 4.23182 3.36842 5.18182V17.2727C3.36842 18.2227 4.12632 19 5.05263 19H14.3158C15.2421 19 16 18.2227 16 17.2727V5.18182C16 4.23182 15.2421 3.45455 14.3158 3.45455ZM14.3158 17.2727H5.05263V5.18182H14.3158V17.2727Z" fill="${this.primaryColor}"/>
    </svg>
    `
    return 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(image)
  }

  get isAddPointMode () {
    return this.mode === 'addPoint'
  }

  get primaryColor () {
    if (!loyaltyConfig?.primary_color || loyaltyConfig?.primary_color === '') {
      return '#FF5733'
    }
    return loyaltyConfig.primary_color
  }

  get shopLogo () {
    if (loyaltyConfig?.loyalty_logo) {
      return loyaltyConfig.loyalty_logo
    }
    if(this.logoURL){
      return this.logoURL;
    }
    return this.sableDefault
  }
  get shopLogoSize () {
    if (loyaltyConfig?.loyalty_logo) {
      if (loyaltyConfig?.loyalty_logo_size) {
        return loyaltyConfig.loyalty_logo_size
      }
      return '75%'
    }
    return '100%'
  }

  async onDecode(qrCode: string) {
    try {
      const splitLink = qrCode.split("qr=");
      const qrData = await CustomerAPI.getQr(splitLink[1]);
      if (qrData[0].brand_id) {
          this.$router.push({ name: "Customer_Home", params: { brandId: qrData[0].brand_id }, query: { qr: this.$route.query.qr } });
      } else {
        this.$router.push({ name: "Customer", query: { qr: "failed" } });
      }
    } catch {
      this.$router.push({ name: "Customer", query: { qr: "failed" } });
    }
  }

  goToVerifyOTP () {
    if(this.$route.name === 'Customer_Coupon') {
      this.$store.commit('customer/SET_REDIRECT_AFTER_VERIFIED', 'Customer_History')
    } else if (loyaltyConfig?.brand_id) {
      this.$store.commit('customer/SET_REDIRECT_AFTER_VERIFIED', 'Customer_History')
    }
    this.$router.push({ name: "Customer_OTP" });
  }

  async copyText(){
    await this.$copyText(this.code, this.$refs.copyContainer)
  }

  async copyRedeemLink(){
    await this.$copyText(process.env.VUE_APP_LINELIFF_URL + '/redeem/reward/' + this.code, this.$refs.copyContainer)
    this.isShowTooltipLinkCopy = true
  }
}
